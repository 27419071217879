import { ROUTES } from '@/constants/routes';
import { IMenuItemType } from '@/ui';
import { TFunction } from 'i18next';
import { NavLink } from 'react-router-dom';

export enum MenuKeys {
  WEBSITE_MANAGEMENT = '/website-management',
}

export const getMenuConfig = (t: TFunction): IMenuItemType[] => [
  {
    key: MenuKeys.WEBSITE_MANAGEMENT,
    label: <span>{t('menu_website_management')}</span>,
    children: [
      {
        key: ROUTES.GENERAL_SETTINGS,
        label: (
          <NavLink to={ROUTES.GENERAL_SETTINGS} end>
            {t('menu_general_settings')}
          </NavLink>
        ),
      },
      {
        key: ROUTES.UI_SETTINGS,
        label: (
          <NavLink to={ROUTES.UI_SETTINGS} end>
            {t('menu_ui')}
          </NavLink>
        ),
      },
      {
        key: ROUTES.SHIPPING_PRICES,
        label: (
          <NavLink to={ROUTES.SHIPPING_PRICES} end>
            {t('menu_shipping_prices')}
          </NavLink>
        ),
      },
    ],
  },
  {
    key: ROUTES.PAGES_MANAGEMENT,
    label: <NavLink to={ROUTES.PAGES_MANAGEMENT}>{t('menu_pages_management')}</NavLink>,
  },
  {
    key: ROUTES.REVIEWS,
    label: <NavLink to={ROUTES.REVIEWS}>{t('menu_reviews')}</NavLink>,
  },
  {
    key: ROUTES.CHANNELS,
    label: <NavLink to={ROUTES.CHANNELS}>{t('menu_channels')}</NavLink>,
  },
  {
    key: ROUTES.SEO,
    label: <span>{t('menu_seo')}</span>,
    children: [
      {
        key: ROUTES.REDIRECTS,
        label: (
          <NavLink to={ROUTES.REDIRECTS} end>
            {t('menu_redirects')}
          </NavLink>
        ),
      },
      {
        key: ROUTES.METATAGS_TEMPLATES,
        label: (
          <NavLink to={ROUTES.METATAGS_TEMPLATES} end>
            {t('menu_metatags_templates')}
          </NavLink>
        ),
      },
      {
        key: ROUTES.ROBOTS_TXT,
        label: (
          <NavLink to={ROUTES.ROBOTS_TXT} end>
            {t('menu_robots_txt')}
          </NavLink>
        ),
      },
      {
        key: ROUTES.SITE_MAP,
        label: (
          <NavLink to={ROUTES.SITE_MAP} end>
            {t('menu_site_map')}
          </NavLink>
        ),
      },
    ],
  },
  {
    key: ROUTES.AKENEO,
    label: <NavLink to={ROUTES.AKENEO}>{t('menu_akeneo')}</NavLink>,
  },
  {
    key: ROUTES.VARIABLES,
    label: <NavLink to={ROUTES.VARIABLES}>{t('menu_variables')}</NavLink>,
  },
];
